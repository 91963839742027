<template>
  <div class="page">
    <Header />
    <div class="wrap">
      <div class="register">
        <div class="form">
          <a-form id="formForgot" :form="form">
            <div>
              <!-- 学生信息 -->
              <div class="section">
                <div class="part">
                  <div class="title"><span class="line"></span>学生信息</div>
                </div>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="学生姓名">
                      <a-input
                        class="cus-input"
                        size="large"
                        type="text"
                        placeholder="请输入学生姓名"
                        disabled
                        v-decorator="[
                          'xm',
                          {
                            rules: [
                              { required: true, message: '请输入学生姓名' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="证件号码">
                      <a-input
                        class="cus-input"
                        size="large"
                        type="text"
                        placeholder="请输入证件号码"
                        :maxLength="18"
                        disabled
                        @blur="getIdCardInfo($event)"
                        v-decorator="[
                          'zjh',
                          {
                            rules: [{ required: true, message: '' }],
                          },
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="性别">
                      <a-radio-group
                        @change="sexChange"
                        disabled
                        v-decorator="[
                          'xb',
                          {
                            rules: [{ required: true, message: '请选择性别' }],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-radio value="1"> 男 </a-radio>
                        <a-radio value="2"> 女 </a-radio>
                      </a-radio-group>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="出生日期">
                      <a-date-picker
                        size="large"
                        style="width: 100%"
                        disabled
                        placeholder="请选择出生日期"
                        v-decorator="[
                          'csrq',
                          {
                            rules: [
                              { required: true, message: '请选择出生日期' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
              <!-- 户籍信息 -->
              <div class="section">
                <div class="part">
                  <div class="title"><span class="line"></span>户籍信息</div>
                  <div
                    class="scan"
                    v-if="
                      !hjProvinceDisabled ||
                      !hjCityDisabled ||
                      !hjCountyDisabled
                    "
                  >
                    <!-- @click="scanImg('hkb', 1)" -->
                    <!-- <a-icon type="camera" class="icon" />识别户口户主页 -->
                    <!-- <a-upload
                      name="file"
                      action="/platform/files/publish/file/doupload"
                      :before-upload="beforeUpload"
                      @preview="handlePreview"
                      @change="selectImage($event, 'hkb', 1)"
                      v-decorator="['hkbUrl']"
                    >
                      <a-button type="link" style="padding:0;"><a-icon type="camera" /> 识别户口簿首页 </a-button>
                    </a-upload> -->
                  </div>
                  <div class="del" @click="clear('hjInfo')" v-else>
                    <a-icon type="delete" class="icon" />户籍信息如有误请<span
                      class="del"
                      >修改</span
                    >
                  </div>
                </div>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="上传户口簿首页">
                      <a-upload
                        action="/platform/files/publish/file/doupload"
                        list-type="picture-card"
                        accept="image/*"
                        :file-list="hkbFileList"
                        :before-upload="beforeUpload"
                        @preview="handlePreview"
                        @change="selectImage($event, 'hkb', 1)"
                        v-decorator="[
                          'hkbUrl',
                          {
                            rules: [
                              { required: true, message: '请上传户口簿' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <div v-if="hkbFileList.length < 1">
                          <a-icon
                            type="plus"
                            style="font-size: 32px; color: #999"
                          />
                        </div>
                      </a-upload>
                    </a-form-item>
                    <div class="lookDemo" @click="tolookDemo('hkb')">
                      <a-icon type="picture" class="icon" /> 查看样例
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="上传户口簿户主页">
                      <a-upload
                        action="/platform/files/publish/file/doupload"
                        list-type="picture-card"
                        accept="image/*"
                        :file-list="hkbHzFileList"
                        :before-upload="beforeUpload"
                        @preview="handlePreview"
                        @change="selectImage($event, 'hkbhz', 2)"
                        v-decorator="[
                          'hkbHzUrl',
                          {
                            rules: [
                              { required: true, message: '请上传户口簿户主页' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <div v-if="hkbHzFileList.length < 1">
                          <a-icon
                            type="plus"
                            style="font-size: 32px; color: #999"
                          />
                        </div>
                      </a-upload>
                    </a-form-item>
                    <div class="lookDemo" @click="tolookDemo('hkbhz')">
                      <a-icon type="picture" class="icon" /> 查看样例
                    </div>
                  </a-col>
                </a-row>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="上传户口簿监护人页">
                      <a-upload
                        action="/platform/files/publish/file/doupload"
                        list-type="picture-card"
                        accept="image/*"
                        :file-list="hkbJhrFileList"
                        :before-upload="beforeUpload"
                        @preview="handlePreview"
                        @change="selectImage($event, 'hkbjhr', 2)"
                        v-decorator="[
                          'hkbJhrUrl',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请上传户口簿监护人页',
                              },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <div v-if="hkbJhrFileList.length < 2">
                          <a-icon
                            type="plus"
                            style="font-size: 32px; color: #999"
                          />
                        </div>
                      </a-upload>
                    </a-form-item>
                    <div class="lookDemo" @click="tolookDemo('hkbjhr')">
                      <a-icon type="picture" class="icon" /> 查看样例
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="上传户口簿学生页">
                      <a-upload
                        action="/platform/files/publish/file/doupload"
                        list-type="picture-card"
                        accept="image/*"
                        :file-list="hkbXsFileList"
                        :before-upload="beforeUpload"
                        @preview="handlePreview"
                        @change="selectImage($event, 'hkbxs', 2)"
                        v-decorator="[
                          'hkbXsUrl',
                          {
                            rules: [
                              { required: true, message: '请上传户口簿学生页' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <div v-if="hkbXsFileList.length < 1">
                          <a-icon
                            type="plus"
                            style="font-size: 32px; color: #999"
                          />
                        </div>
                      </a-upload>
                    </a-form-item>
                    <div class="lookDemo" @click="tolookDemo('hkbxs')">
                      <a-icon type="picture" class="icon" /> 查看样例
                    </div>
                  </a-col>
                </a-row>
                <a-row :gutter="80">
                  <a-col :span="24">
                    <div class="tip">
                      温馨提示：<br />
                      1.图片大小限制在{{
                        fileSizeText
                      }}以内，支持的图片格式为png、jpg、jpeg。<br />
                      2.请上传户口簿首页（1张）、户口簿户主页（1张）、监护人页（最多2张）及学生页（1张）。
                    </div>
                  </a-col>
                </a-row>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="户籍地 - 省">
                      <a-select
                        size="large"
                        placeholder="请选择户籍地省"
                        @change="provinceChange"
                        :disabled="hjProvinceDisabled"
                        v-decorator="[
                          'hjProvinceText',
                          {
                            rules: [
                              { required: true, message: '请选择户籍地-省' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-select-option
                          v-for="item in provinceList"
                          :key="item.rSname"
                          :value="item.rSname"
                        >
                          {{ item.rSname }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="户籍地 - 市">
                      <a-select
                        size="large"
                        placeholder="请选择户籍地市"
                        @change="cityChange"
                        :disabled="hjCityDisabled"
                        v-decorator="[
                          'hjCityText',
                          {
                            rules: [
                              { required: true, message: '请选择户籍地-市' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-select-option
                          v-for="item in cityList"
                          :key="item.rSname"
                          :value="item.rSname"
                        >
                          {{ item.rSname }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="户籍地 - 区（县）">
                      <a-select
                        size="large"
                        placeholder="请选择户籍地区（县）"
                        @change="areaChange"
                        :disabled="hjCountyDisabled"
                        v-decorator="[
                          'hjCountyText',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择户籍地区（县）',
                              },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-select-option
                          v-for="item in areaList"
                          :key="item.rSname"
                          :value="item.rSname"
                        >
                          {{ item.rSname }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="户籍登记详细地址">
                      <a-input
                        class="cus-input"
                        size="large"
                        type="text"
                        placeholder="请输入户籍登记详细地址"
                        :disabled="hjAddressDisabled"
                        v-decorator="[
                          'hjAddress',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入户籍登记详细地址',
                              },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="户籍所在地派出所">
                      <a-select
                        size="large"
                        placeholder="请选择"
                        @change="pcsChange"
                        v-show="areaLS == 1"
                        v-decorator="[
                          'hjSzdpcs',
                          {
                            rules: [{ required: true, message: '请选择' }],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-select-option
                          v-for="item in pcsList"
                          :key="item.pcsCode"
                          :value="item.pcsName"
                        >
                          {{ item.pcsName }}
                        </a-select-option>
                      </a-select>
                      <a-input
                        v-show="areaLS == 2"
                        class="cus-input"
                        size="large"
                        type="text"
                        placeholder="请输入户籍所在地派出所"
                        v-decorator="[
                          'hjSzdpcs',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入户籍所在地派出所',
                              },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="户籍登记（迁入）日期">
                      <a-date-picker
                        size="large"
                        style="width: 100%"
                        placeholder="请选择户籍登记（迁入）日期"
                        v-decorator="[
                          'hjDjrq',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择户籍登记（迁入）日期',
                              },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
              <!-- 房产信息 -->
              <div class="section">
                <div class="part">
                  <div class="title"><span class="line"></span>房产信息</div>
                  <div v-if="fczjLx == 1">
                    <div
                      class="scan"
                      v-if="
                        !fczjBhDisabled ||
                        !fcAddressDisabled ||
                        !fczjSryDisabled
                      "
                    >
                      <!-- @click="scanImg('fczj', 1)" -->
                      <!-- <a-icon type="camera" class="icon"/>识别不动产权证 -->
                      <!-- <a-upload
                        name="file"
                        action="/platform/files/publish/file/doupload"
                        :before-upload="beforeUpload"
                        @preview="handlePreview"
                        @change="selectImage($event, 'fczj', 1)"
                        v-decorator="['fczjUrl']"
                      >
                        <a-button type="link" style="padding:0;"><a-icon type="camera" /> 识别不动产权证 </a-button>
                      </a-upload> -->
                    </div>
                    <div class="del" @click="clear('fcInfo')" v-else>
                      <a-icon type="delete" class="icon" />清空
                    </div>
                  </div>
                </div>
                <!-- 房产 -->
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item :label="`是否在${systemArea}有房`">
                      <a-radio-group
                        @change="sfyfChange"
                        v-decorator="[
                          'fcYw',
                          {
                            rules: [
                              {
                                required: true,
                                message: `请选择是否在${systemArea}有房`,
                              },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-radio value="1"> 是 </a-radio>
                        <a-radio value="2"> 否 </a-radio>
                      </a-radio-group>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" v-if="fcYw == 1">
                    <a-form-item label="证件类型">
                      <a-select
                        size="large"
                        placeholder="请选择"
                        @change="fczjLxChange"
                        v-decorator="[
                          'fczjLx',
                          {
                            rules: [{ required: true, message: '请选择' }],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-select-option
                          v-for="item in fczjlxList"
                          :key="item.value"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <!-- v-if="fcYw == 1" -->
                <div v-if="fcYw == 1">
                  <a-row :gutter="80" v-if="fczjLx == 1">
                    <a-col :span="24">
                      <a-form-item label="上传房产证">
                        <a-upload
                          action="/platform/files/publish/file/doupload"
                          list-type="picture-card"
                          accept="image/*"
                          :file-list="fczjFileList"
                          :before-upload="beforeUpload"
                          @preview="handlePreview"
                          @change="selectImage($event, 'fczj', 1)"
                          v-decorator="[
                            'fczjUrl',
                            {
                              rules: [
                                { required: true, message: '请上传房产证' },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        >
                          <div v-if="fczjFileList.length < 1">
                            <a-icon
                              type="plus"
                              style="font-size: 32px; color: #999"
                            />
                          </div>
                        </a-upload>
                      </a-form-item>
                      <div class="lookDemo" @click="tolookDemo('fczj')">
                        <a-icon type="picture" class="icon" /> 查看样例
                      </div>
                      <div class="tip">
                        温馨提示：<br />
                        1.上传房产证照片后，如自动识别的信息有误，请点击右上角“清空”后手动输入。<br />
                        2.请上传房产证所有权人及房产地址页，图片大小限制在{{
                          fileSizeText
                        }}以内， 支持上传文件个数最多为1张，
                        支持的图片格式为png、jpg、jpeg。<br />
                      </div>
                    </a-col>
                  </a-row>
                  <a-row :gutter="80" v-if="fczjLx == 2">
                    <a-col :span="24">
                      <a-form-item label="上传购房合同信息">
                        <a-upload
                          action="/platform/files/publish/file/doupload"
                          list-type="picture-card"
                          accept="image/*"
                          :file-list="fczjFileList"
                          :before-upload="beforeUpload"
                          @preview="handlePreview"
                          @change="selectImage($event, 'fczj', 2)"
                          v-decorator="[
                            'fczjUrl',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请上传购房合同信息',
                                },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        >
                          <div v-if="fczjFileList.length < 5">
                            <a-icon
                              type="plus"
                              style="font-size: 32px; color: #999"
                            />
                          </div>
                        </a-upload>
                      </a-form-item>
                      <div class="lookDemo" @click="tolookDemo('fczj')">
                        <a-icon type="picture" class="icon" /> 查看样例
                      </div>
                      <div class="tip">
                        温馨提示：<br />
                        1.图片大小限制在{{ fileSizeText }}以内，
                        支持上传文件个数最多为5张，
                        支持的图片格式为png、jpg、jpeg。<br />
                        2.若选择购房合同，必须上传合同签订双方页，盖章页，准确地址页。
                      </div>
                    </a-col>
                  </a-row>
                  <a-row :gutter="80">
                    <a-col :span="12">
                      <a-form-item label="所有权人姓名">
                        <a-input
                          :disabled="fczjSryDisabled"
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'fczjSry',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入所有权人姓名',
                                },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="房产地址所在街道(乡镇)">
                        <!-- <a-input
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'fcStreet',
                            {
                              rules: [{ required: true, message: '请输入房产所在街道(乡镇)' }],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input> -->
                        <a-select
                          size="large"
                          placeholder="请选择"
                          @change="fczStreetChange"
                          v-decorator="[
                            'fcStreet',
                            {
                              rules: [{ required: true, message: '请选择' }],
                              validateTrigger: 'change',
                            },
                          ]"
                        >
                          <a-select-option
                            v-for="item in streetList"
                            :key="item.jdmc"
                            :value="item.jdmc"
                          >
                            {{ item.jdmc }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="80">
                    <a-col :span="12">
                      <a-form-item label="房产地址所在社区(村)">
                        <!-- <a-input
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'fcCommunity',
                            {
                              rules: [{ required: true, message: '请输入房产所在社区(村)' }],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input> -->
                        <a-select
                          size="large"
                          placeholder="请选择"
                          v-decorator="[
                            'fcCommunity',
                            {
                              rules: [{ required: true, message: '请选择' }],
                              validateTrigger: 'change',
                            },
                          ]"
                        >
                          <a-select-option
                            v-for="item in fczSqList"
                            :key="item.jdmc"
                            :value="item.jdmc"
                          >
                            {{ item.jdmc }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="房产登记详细地址">
                        <a-input
                          :disabled="fcAddressDisabled"
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'fcAddress',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入房产登记详细地址',
                                },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="80">
                    <a-col :span="12">
                      <a-form-item label="证件合同编号">
                        <a-input
                          :disabled="fczjBhDisabled"
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'fczjBh',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入证件合同编号',
                                },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="与适龄儿童关系">
                        <a-select
                          size="large"
                          placeholder="请选择"
                          @change="gxChange"
                          v-decorator="[
                            'fczjYxxgx',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择与适龄儿童关系',
                                },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        >
                          <a-select-option
                            v-for="item in fcgxList"
                            :key="item.value"
                            :value="item.value"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </div>
              </div>
              <!-- 居住证信息 -->
              <div class="section" v-if="areaLS == 2">
                <div class="part">
                  <div class="title"><span class="line"></span>居住证信息</div>
                </div>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item :label="`是否在${systemArea}有居住证`">
                      <a-radio-group
                        @change="sfyjzzChange"
                        v-decorator="[
                          'jzzYw',
                          {
                            rules: [
                              {
                                required: true,
                                message: `请选择是否在${systemArea}有居住证`,
                              },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-radio value="1"> 是 </a-radio>
                        <a-radio value="2"> 否 </a-radio>
                      </a-radio-group>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" v-if="jzzYw == 1">
                    <a-form-item label="持证人姓名">
                      <a-input
                        class="cus-input"
                        size="large"
                        type="text"
                        placeholder="请输入"
                        v-decorator="[
                          'jzzCry',
                          {
                            rules: [
                              { required: true, message: '请输入持证人姓名' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <div v-if="jzzYw == 1">
                  <a-row :gutter="80">
                    <a-col :span="12">
                      <a-form-item label="居住证编号">
                        <a-input
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'jzzBh',
                            {
                              rules: [
                                { required: true, message: '请输入居住证编号' },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="居住证发证机关">
                        <a-select
                          size="large"
                          placeholder="请选择"
                          v-decorator="[
                            'jzzFzjg',
                            {
                              rules: [{ required: true, message: '请选择' }],
                              validateTrigger: 'change',
                            },
                          ]"
                        >
                          <a-select-option
                            v-for="item in pcsList"
                            :key="item.pcsCode"
                            :value="item.pcsName"
                          >
                            {{ item.pcsName }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="80">
                    <a-col :span="12">
                      <a-form-item label="居住证所属街道">
                        <!-- <a-input
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'jzzStreet',
                            {
                              rules: [
                                { required: true, message: '请输入居住证所属街道' },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input> -->
                        <a-select
                          size="large"
                          placeholder="请选择"
                          @change="jzzStreetChange"
                          v-decorator="[
                            'jzzStreet',
                            {
                              rules: [{ required: true, message: '请选择' }],
                              validateTrigger: 'change',
                            },
                          ]"
                        >
                          <a-select-option
                            v-for="item in streetList"
                            :key="item.jdmc"
                            :value="item.jdmc"
                          >
                            {{ item.jdmc }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="居住证所属社区(村)">
                        <!-- <a-input
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'jzzCommunity',
                            {
                              rules: [
                                { required: true, message: '请输入居住证所属社区(村)' },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input> -->
                        <a-select
                          size="large"
                          placeholder="请选择"
                          v-decorator="[
                            'jzzCommunity',
                            {
                              rules: [{ required: true, message: '请选择' }],
                              validateTrigger: 'change',
                            },
                          ]"
                        >
                          <a-select-option
                            v-for="item in jzzSqList"
                            :key="item.jdmc"
                            :value="item.jdmc"
                          >
                            {{ item.jdmc }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="80">
                    <a-col :span="12">
                      <a-form-item label="居住证登记详细地址">
                        <a-input
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'jzzAddress',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入居住证登记详细地址',
                                },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="80">
                    <a-col :span="24">
                      <a-form-item label="上传居住证">
                        <a-upload
                          action="/platform/files/publish/file/doupload"
                          list-type="picture-card"
                          accept="image/*"
                          :file-list="jzzFileList"
                          :before-upload="beforeUpload"
                          @preview="handlePreview"
                          @change="selectImage($event, 'jzz', 2)"
                          v-decorator="[
                            'jzzUrl',
                            {
                              rules: [
                                { required: true, message: '请上传居住证' },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        >
                          <div v-if="jzzFileList.length < 3">
                            <a-icon
                              type="plus"
                              style="font-size: 32px; color: #999"
                            />
                          </div>
                        </a-upload>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </div>
              </div>
              <!-- 营业执照信息 -->
              <div class="section" v-if="areaLS == 2">
                <div class="part">
                  <div class="title">
                    <span class="line"></span>营业执照信息
                  </div>
                </div>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item :label="`是否在${systemArea}有营业执照`">
                      <a-radio-group
                        @change="sfyyyzzChange"
                        v-decorator="[
                          'yyzzYw',
                          {
                            rules: [
                              {
                                required: true,
                                message: `请选择是否在${systemArea}有营业执照`,
                              },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-radio value="1"> 是 </a-radio>
                        <a-radio value="2"> 否 </a-radio>
                      </a-radio-group>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" v-if="yyzzYw == 1">
                    <a-form-item label="营业执照法人">
                      <a-input
                        class="cus-input"
                        size="large"
                        type="text"
                        placeholder="请输入"
                        v-decorator="[
                          'yyzzFr',
                          {
                            rules: [
                              { required: true, message: '请输入营业执照法人' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
                <div v-if="yyzzYw == 1">
                  <a-row :gutter="80">
                    <a-col :span="12">
                      <a-form-item label="营业执照注册时间">
                        <a-date-picker
                          size="large"
                          style="width: 100%"
                          placeholder="请选择营业执照注册时间"
                          v-decorator="[
                            'yyzzZcsj',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择营业执照注册时间',
                                },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="营业执照编号">
                        <a-input
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'yyzzBh',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入营业执照编号',
                                },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="80">
                    <a-col :span="12">
                      <a-form-item label="营业执照登记详细地址">
                        <a-input
                          class="cus-input"
                          size="large"
                          type="text"
                          placeholder="请输入"
                          v-decorator="[
                            'yyzzDjAddress',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入营业执照登记详细地址',
                                },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        ></a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="营业执照是否年检">
                        <a-radio-group v-decorator="['yyzzSfnj']">
                          <a-radio value="1"> 是 </a-radio>
                          <a-radio value="2"> 否 </a-radio>
                        </a-radio-group>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row :gutter="80">
                    <a-col :span="24">
                      <a-form-item label="上传营业执照">
                        <a-upload
                          action="/platform/files/publish/file/doupload"
                          list-type="picture-card"
                          accept="image/*"
                          :file-list="yyzzFileList"
                          :before-upload="beforeUpload"
                          @preview="handlePreview"
                          @change="selectImage($event, 'yyzz', 2)"
                          v-decorator="[
                            'yyzzUrl',
                            {
                              rules: [
                                { required: true, message: '请上传营业执照' },
                              ],
                              validateTrigger: 'change',
                            },
                          ]"
                        >
                          <div v-if="yyzzFileList.length < 5">
                            <a-icon
                              type="plus"
                              style="font-size: 32px; color: #999"
                            />
                          </div>
                        </a-upload>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </div>
              </div>
              <!-- 监护人信息 -->
              <div class="section">
                <div class="part">
                  <div class="title"><span class="line"></span>监护人信息</div>
                  <div class="scan" v-if="CszSbInfo.length == 0">
                    <!-- @click="scanImg('csz', 1)" -->
                    <!-- <a-icon type="camera" class="icon"/>识别测试 -->
                    <!-- <a-upload
                      name="file"
                      action="/platform/files/publish/file/doupload"
                      :before-upload="beforeUpload"
                      @preview="handlePreview"
                      @change="selectImage($event, 'csz', 1)"
                      v-decorator="['cszUrl']"
                    >
                      <a-button type="link" style="padding:0;"><a-icon type="camera" /> 识别出生医学证明 </a-button>
                    </a-upload> -->
                  </div>
                  <div class="del" @click="clear('cszInfo')" v-else>
                    <a-icon type="delete" class="icon" /> 清空
                  </div>
                </div>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="上传出生医学证明">
                      <a-upload
                        action="/platform/files/publish/file/doupload"
                        list-type="picture-card"
                        accept="image/*"
                        :file-list="cszFileList"
                        :before-upload="beforeUpload"
                        @preview="handlePreview"
                        @change="selectImage($event, 'csz', 2)"
                        v-decorator="[
                          'cszUrl',
                          {
                            rules: [
                              { required: true, message: '请上传出生医学证明' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <div v-if="cszFileList.length < 1">
                          <a-icon
                            type="plus"
                            style="font-size: 32px; color: #999"
                          />
                        </div>
                      </a-upload>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="上传结婚证">
                      <a-upload
                        action="/platform/files/publish/file/doupload"
                        list-type="picture-card"
                        accept="image/*"
                        :file-list="jhzFileList"
                        :before-upload="beforeUpload"
                        @preview="handlePreview"
                        @change="selectImage($event, 'jhz', 2)"
                        v-decorator="[
                          'jhzUrl',
                          {
                            rules: [
                              { required: true, message: '请上传结婚证' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <div v-if="jhzFileList.length < 2">
                          <a-icon
                            type="plus"
                            style="font-size: 32px; color: #999"
                          />
                        </div>
                      </a-upload>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="监护人姓名">
                      <a-input
                        class="cus-input"
                        size="large"
                        type="text"
                        placeholder="请输入监护人姓名"
                        v-decorator="[
                          'familyList[0].fXm',
                          {
                            rules: [
                              { required: true, message: '请输入监护人姓名' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="与适龄儿童关系">
                      <a-select
                        size="large"
                        placeholder="请选择"
                        v-decorator="[
                          'familyList[0].gx',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择与适龄儿童关系',
                              },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-select-option
                          v-for="item in gxList"
                          :key="item.value"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="监护人身份证号">
                      <a-input
                        class="cus-input"
                        size="large"
                        type="text"
                        :maxLength="18"
                        placeholder="请输入监护人身份证号"
                        v-decorator="[
                          'familyList[0].sfzh',
                          {
                            rules: [
                              { required: true, message: '' },
                              { validator: idCardValidate },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="工作单位类型">
                      <a-select
                        size="large"
                        placeholder="请选择"
                        @change="dwTypeChange"
                        v-decorator="[
                          'familyList[0].dwType',
                          {
                            rules: [
                              { required: true, message: '请选择工作单位类型' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-select-option
                          v-for="item in dwTypeList"
                          :key="item.value"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="80">
                  <a-col :span="12">
                    <a-form-item label="手机号">
                      <a-input
                        class="cus-input"
                        size="large"
                        type="text"
                        :maxLength="11"
                        placeholder="请输入监护人手机号"
                        v-decorator="[
                          'familyList[0].lxdh',
                          {
                            rules: [
                              { required: true, message: '' },
                              { validator: validatePhone },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      ></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item label="验证码">
                      <a-input
                        class="cus-input"
                        size="large"
                        type="text"
                        placeholder="请输入验证码"
                        autocomplete="off"
                        :readonly="readOnly"
                        @click="
                          () => {
                            this.readOnly = false;
                          }
                        "
                        v-decorator="[
                          'code',
                          {
                            rules: [
                              { required: true, message: '请输入验证码' },
                            ],
                            validateTrigger: 'change',
                          },
                        ]"
                      >
                        <a-button
                          type="link"
                          class="codeBtn"
                          slot="addonAfter"
                          :disabled="codeDisabled"
                          @click="sendCode"
                          >{{ btnText }}</a-button
                        >
                      </a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
            </div>

            <!-- 图片预览 -->
            <a-modal
              :width="800"
              :visible="previewVisible"
              :footer="null"
              @cancel="imgHandleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
            <a-modal
              :width="800"
              :visible="previewList"
              :footer="null"
              @cancel="previewList = false"
            >
              <div v-for="(item, idx) in previewImageList" :key="idx">
                <img alt="example" style="width: 100%" :src="item" />
              </div>
            </a-modal>
            <!-- 注册结果 -->
            <a-modal
              v-model="registerVisible"
              title="注册结果"
              :footer="null"
              :width="480"
              :closable="false"
              :centered="true"
              :maskClosable="false"
            >
              <div class="resultWrap">
                <a-icon class="icon" type="check-circle" theme="filled" />
                <div class="title">{{ registerTitle }}</div>
                <div class="desc">{{ registerDesc }}</div>
                <!-- <div class="desc">请耐心等待校验结果，校验通过后方可登记详细信息，如未完善详细信息，视为无效信息，不予安排学位！校验期间可以使用儿童身份证号码登录系统查询校验结果！</div> -->
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  class="result-btn btn"
                  @click="ok"
                >
                  确定
                </a-button>
              </div>
            </a-modal>

            <!-- 户口识别确认框 -->
            <a-modal
              v-model="showHkbModal"
              title="温馨提示"
              :footer="null"
              :width="480"
              :closable="false"
              :centered="true"
              :maskClosable="false"
            >
              <div class="resultWrap">
                <a-icon class="icon" type="info-circle" theme="filled" />
                <div class="hkbInfoWrap">
                  <div class="tip">您是否使用以下识别信息代替手动填写？</div>
                  <div class="hkbInfo desc">
                    户籍-省：{{ HkbSbInfo.province }}
                  </div>
                  <div class="hkbInfo desc">户籍-市：{{ HkbSbInfo.city }}</div>
                  <div class="hkbInfo desc">
                    户籍-县（区）：{{ HkbSbInfo.county }}
                  </div>
                  <div class="tip">（确定后，需手动补充页面中空白信息）</div>
                </div>
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  class="result-btn btn"
                  @click="hkbSbOk"
                >
                  是，自动填写
                </a-button>
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  class="cancel-btn btn"
                  @click="cancelHkbModal"
                >
                  否，手动填写
                </a-button>
              </div>
            </a-modal>

            <!-- 出生证识别确认框 -->
            <a-modal
              v-model="showCszModal"
              :footer="null"
              :width="480"
              :closable="false"
              :centered="true"
              :maskClosable="false"
            >
              <div style="color: #f44; font-size: 16px">请选择第一监护人：</div>
              <div class="resultWrap">
                <!-- <a-icon class="icon" type="info-circle" theme="filled" /> -->
                <div class="jhrWrap">
                  <div class="tip">
                    请认真核对监护人身份信息，如有误，点击确定按钮，进行修改。
                  </div>
                  <div
                    class="item"
                    v-for="(item, idx) in CszSbInfo"
                    :key="idx"
                    :class="activeJhr == idx ? 'active' : ''"
                    @click="changeJhr(idx)"
                  >
                    <div class="text">姓名：{{ item.fXm }}</div>
                    <div class="text">身份证号：{{ item.sfzh }}</div>
                  </div>
                </div>
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  class="result-btn btn"
                  @click="cszSbOk"
                >
                  确定
                </a-button>
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  class="cancel-btn btn"
                  @click="cancelCszModal"
                >
                  取消
                </a-button>
              </div>
            </a-modal>

            <a-row :gutter="24">
              <a-col :span="10" :offset="7">
                <a-popconfirm
                  placement="top"
                  ok-text="确认"
                  cancel-text="取消"
                  @confirm="handleSubmit"
                >
                  <template slot="title">
                    <p>温馨提示</p>
                    <p>确定提交填报信息吗？</p>
                  </template>
                  <a-button
                    type="primary"
                    shape="round"
                    size="large"
                    :loading="btnDisabled"
                    block
                    class="btn"
                  >
                    提交
                  </a-button>
                </a-popconfirm>
                <!-- <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  :loading="btnDisabled"
                  block
                  htmlType="submit"
                  class="btn"
                >
                  提交
                </a-button> -->
              </a-col>
            </a-row>
          </a-form>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import moment from "moment";
import pick from "lodash.pick";
import Header from "@/components/header";
import Footer from "@/components/footer";
import {
  registerTwo,
  registerCode,
  getPcsList,
  getStreet,
  getSq,
} from "@/api/api";
import { getDetail } from "@/api/form";
import { getCity, getDicAll, hkbSb, fczSb, cszmSb } from "@/api/common";
import {
  telValid,
  idValid,
  getBirthFromIdCard,
  getSexFromIdCard,
} from "@/utils/reg.js";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "RegisterTwo",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      btnDisabled: false,
      imgOrigin: this.$store.state.fileUrl,
      form: this.$form.createForm(this),
      stage: localStorage.getItem("stage"),
      btnText: "获取验证码",
      hjProvinceDisabled: false, // 户籍信息是否可修改
      hjCityDisabled: false,
      hjCountyDisabled: false,
      hjAddressDisabled: false, // end
      fczjBhDisabled: false, // 房产信息是否可修改
      fcAddressDisabled: false,
      fczjSryDisabled: false, // end
      codeDisabled: false,
      areaLS: "", // 1离石  2非离石
      provinceList: [], // 省
      cityList: [], // 市
      areaList: [], // 县
      // ---------------
      hjProvinceText: "", // 户籍地 start
      hjProvinceCode: "",
      hjCityText: "",
      hjCityCode: "",
      hjCountyText: "",
      hjCountyCode: "", // 户籍地 end
      xb: "", // 性别
      csrq: "", // 出生日期
      fcYw: "", // 有无房产
      jzzYw: "", // 有无居住证
      yyzzYw: "", // 有无营业执照
      fczjLx: "", // 房产证件类型
      // ----------------
      imageData: "",
      // vCode: '', // 图形验证码
      zjlxList: [], // 身份证件类型
      fczjlxList: [], // 房产证件类型
      fcgxList: [], // 房产与本人关系列表
      gxList: [], // 关系列表
      dwTypeList: [], // 工作/就业单位类型
      hkbUrlList: [], // 户口簿--首页
      hkbHzUrlList: [], // 户口簿--户主页
      hkbJhrUrlList: [], // 户口簿--监护人页
      hkbXsUrlList: [], // 户口簿--学生页
      fczjUrlList: [], // 房产证
      jzzUrlList: [], // 居住证
      yyzzUrlList: [], // 营业执照
      jhzUrlList: [], // 结婚证
      cszUrlList: [], // 出生医学证明
      hkbFileList: [], // 户口簿--首页
      hkbHzFileList: [], // 户口簿--户主页
      hkbJhrFileList: [], // 户口簿--监护人页
      hkbXsFileList: [], // 户口簿--学生页
      fczjFileList: [], // 房产证
      jzzFileList: [], // 居住证
      yyzzFileList: [], // 营业执照
      jhzFileList: [], // 结婚证
      cszFileList: [], // 出生医学证明
      previewVisible: false, // 图片预览
      previewList: false, // 图片预览list
      previewImage: "",
      previewImageList: [],
      registerVisible: false, // 注册结果
      registerTitle: "",
      registerDesc: "",
      readOnly: true,
      detailInfo: {},
      hkbIsSb: 2, // 户口簿识别  1是   2否
      fczjIsSb: 2, // 房产证识别  1是   2否
      jhrIsSb: 2, // 出生证识别  1是   2否
      pcsList: [], // 派出所列表
      showHkbModal: false, // 户口簿识别确认
      HkbSbInfo: {},
      showCszModal: false, // 出生证识别确认
      CszSbInfo: [], // 监护人列表
      familyList: [
        {
          gx: "", // 与适龄儿童关系
          fXm: "", // 姓名
          sfzh: "", // 身份证号
          lxdh: "", // 联系电话
          dwType: "", // 单位类型
          sfdyjhy: "1", // // 第一监护人标识
        },
        {
          gx: "", // 与适龄儿童关系
          fXm: "", // 姓名
          sfzh: "", // 身份证号
          lxdh: "", // 联系电话
          dwType: "", // 单位类型
        },
      ],
      streetList: [], // 街道
      fczSqList: [], // 社区、村
      jzzSqList: [], // 社区、村
      fczStreetCode: "", // 房产证街道code，查询社区用
      jzzStreetCode: "", // 居住证街道code，查询社区用
      fileSizeText: "5M", // 文件大小上限
      systemArea: localStorage.getItem("systemArea"),
      systemAreaCode: localStorage.getItem("systemAreaCode"),
    };
  },
  methods: {
    // 注册结果确定按钮
    ok() {
      this.registerVisible = false;
      this.$router.replace({
        name: "Login",
      });
    },
    // 获取街道
    getStreetList() {
      getStreet({
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        this.streetList = res.data;
      });
    },
    // 获取房产证社区（村）
    getfczSqList() {
      getSq({
        schoolCode: localStorage.getItem("schoolCode"),
        pbm: this.fczStreetCode,
      }).then((res) => {
        this.fczSqList = res.data;
      });
    },
    // 获取居住证社区（村）
    getjzzSqList() {
      getSq({
        schoolCode: localStorage.getItem("schoolCode"),
        pbm: this.jzzStreetCode,
      }).then((res) => {
        this.jzzSqList = res.data;
      });
    },
    // 房产证街道选择
    fczStreetChange(e) {
      this.streetList.forEach((ele, idx) => {
        if (ele.jdmc == e) {
          this.fczStreetCode = ele.jdbm;
        }
      });
      this.form.resetFields(["fcCommunity"]);
      if (this.fczStreetCode) {
        this.getfczSqList();
      }
    },
    // 居住证街道选择
    jzzStreetChange(e) {
      this.streetList.forEach((ele, idx) => {
        if (ele.jdmc == e) {
          this.jzzStreetCode = ele.jdbm;
        }
      });
      this.form.resetFields(["jzzCommunity"]);
      if (this.jzzStreetCode) {
        this.getjzzSqList();
      }
    },
    // 获取派出所列表
    getPcsList() {
      getPcsList({
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        this.pcsList = res.data;
      });
    },
    // 验证
    validatePhone(rule, value, callback) {
      if (!value) {
        callback("请输入手机号");
      } else if (!telValid(value)) {
        callback("请输入有效手机号");
      }
      callback();
    },
    idCardValidate(rule, value, callback) {
      if (!value) {
        callback("请输入证件号码");
      } else if (!idValid(value)) {
        callback("请输入有效证件号码");
      }
      callback();
    },
    // 身份证号获取性别与出生日期
    getIdCardInfo(e) {
      if (idValid(e.target.value)) {
        let sex = getSexFromIdCard(e.target.value);
        let csrq = getBirthFromIdCard(e.target.value);
        this.form.setFieldsValue({ xb: sex + "" });
        this.form.setFieldsValue({ csrq: csrq });
      }
    },
    // 查看样例
    tolookDemo(type) {
      if (type == "hkb") {
        this.previewImageList = [require("@/assets/hk1.png")];
        this.previewList = true;
      } else if (type == "hkbhz" || type == "hkbjhr" || type == "hkbxs") {
        this.previewImageList = [require("@/assets/hk2.png")];
        this.previewList = true;
      } else if (type == "fczj") {
        this.previewImageList = [require("@/assets/fcz.png")];
        this.previewList = true;
      }
    },
    // 图片识别  type 附件类型  img 图片 url
    scanImg(type, img) {
      if (type == "hkb") {
        hkbSb({
          schoolCode: localStorage.getItem("schoolCode"),
          url: img,
          // url: 'group1/M00/00/01/wKhiNWKe6eeAakcmAAGgnbhT8TQ65..jpg'
        }).then((res) => {
          if (this.hkbIsSb == 1) {
            this.clear("hjInfo");
          }
          if (res.code == 0) {
            if (res.data) {
              this.HkbSbInfo = res.data;
              this.showHkbModal = true;
            }
          } else {
            this.$message.error(res.description);
          }
        });
      } else if (type == "fczj") {
        fczSb({
          schoolCode: localStorage.getItem("schoolCode"),
          url: img,
          // url: `group1/M00/2B/D6/rBqKTWKJoz-AOuByADJeUGlbFhk46..jpg`
        }).then((res) => {
          if (this.fczjIsSb == 1) {
            this.clear("fcInfo");
          }
          if (res.code == 0) {
            if (res.data.fcAddress) {
              this.fcAddressDisabled = true;
              this.form.setFieldsValue({ fcAddress: res.data.fcAddress });
            }
            if (res.data.fczjBh) {
              this.fczjBhDisabled = true;
              this.form.setFieldsValue({ fczjBh: res.data.fczjBh });
            }
            if (res.data.fczjSry) {
              this.fczjSryDisabled = true;
              this.form.setFieldsValue({ fczjSry: res.data.fczjSry });
            }
            this.fczjIsSb = 1; // 识别标识保存
          } else {
            this.$message.error(res.description);
          }
        });
      } else if (type == "csz") {
        cszmSb({
          schoolCode: localStorage.getItem("schoolCode"),
          url: img,
          // url: `group1/M00/00/05/wKhiNWLPyQyASSjjAAG5YnbJTwM34..jpg`
        }).then((res) => {
          if (this.jhrIsSb == 1) {
            this.clear("cszInfo");
          }
          if (res.code == 0) {
            if (res.data.length > 0) {
              this.CszSbInfo = res.data;
              this.showCszModal = true;
            }
          } else {
            this.$message.error(res.description);
          }
        });
      }
    },
    // 户口簿识别确认
    hkbSbOk() {
      if (this.HkbSbInfo.provinceCode) {
        this.hjProvinceDisabled = true;
        this.hjProvinceText = this.HkbSbInfo.province;
        this.hjProvinceCode = this.HkbSbInfo.provinceCode;
        this.form.setFieldsValue({ hjProvinceText: this.hjProvinceText });
        this.form.setFieldsValue({ hjProvinceCode: this.hjProvinceCode });
        this.provinceList.forEach((pro, idx) => {
          if (pro.rCode == this.hjProvinceCode) {
            this.cityList = pro.item;
          }
        });
      }
      if (this.HkbSbInfo.cityCode) {
        this.hjCityDisabled = true;
        this.hjCityText = this.HkbSbInfo.city;
        this.hjCityCode = this.HkbSbInfo.cityCode;
        this.form.setFieldsValue({ hjCityText: this.hjCityText });
        this.form.setFieldsValue({ hjCityCode: this.hjCityCode });
        this.cityList.forEach((city, idx) => {
          if (city.rCode == this.hjCityCode) {
            this.areaList = city.item;
          }
        });
      }
      if (this.HkbSbInfo.countyCode) {
        this.hjCountyDisabled = true;
        this.hjCountyText = this.HkbSbInfo.county;
        this.hjCountyCode = this.HkbSbInfo.countyCode;
        this.form.setFieldsValue({ hjCountyText: this.hjCountyText });
        this.form.setFieldsValue({ hjCountyCode: this.hjCountyCode });
      }
      if (this.HkbSbInfo.countyCode == this.systemAreaCode) {
        this.areaLS = 1;
        this.getPcsList();
      } else {
        this.areaLS = 2;
      }
      if (this.HkbSbInfo.address) {
        // this.hjAddressDisabled = true
        // this.hjAddress = this.HkbSbInfo.address
        // this.form.setFieldsValue({hjAddress: this.hjAddress})
      }
      this.hkbIsSb = 1; // 识别标识保存
      this.showHkbModal = false;
    },
    // 户口簿识别取消确认框
    cancelHkbModal() {
      this.showHkbModal = false;
    },
    // 第一监护人选择
    changeJhr(idx) {
      this.activeJhr = idx;
    },
    // 出生证识别确认
    cszSbOk() {
      if (this.activeJhr == -1) {
        this.$message.error("请选择第一监护人");
        return;
      }
      if (this.activeJhr == 0) {
        this.familyList[0].fXm = this.CszSbInfo[0].fXm;
        this.familyList[0].gx = this.CszSbInfo[0].gx;
        this.familyList[0].sfzh = this.CszSbInfo[0].sfzh;
        this.familyList[1].fXm = this.CszSbInfo[1].fXm;
        this.familyList[1].gx = this.CszSbInfo[1].gx;
        this.familyList[1].sfzh = this.CszSbInfo[1].sfzh;
        this.form.setFieldsValue({
          "familyList[0].fXm": this.CszSbInfo[0].fXm,
        });
        this.form.setFieldsValue({ "familyList[0].gx": this.CszSbInfo[0].gx });
        this.form.setFieldsValue({
          "familyList[0].sfzh": this.CszSbInfo[0].sfzh,
        });
      } else if (this.activeJhr == 1) {
        this.familyList[0].fXm = this.CszSbInfo[1].fXm;
        this.familyList[0].gx = this.CszSbInfo[1].gx;
        this.familyList[0].sfzh = this.CszSbInfo[1].sfzh;
        this.familyList[1].fXm = this.CszSbInfo[0].fXm;
        this.familyList[1].gx = this.CszSbInfo[0].gx;
        this.familyList[1].sfzh = this.CszSbInfo[0].sfzh;
        this.form.setFieldsValue({
          "familyList[0].fXm": this.CszSbInfo[1].fXm,
        });
        this.form.setFieldsValue({ "familyList[0].gx": this.CszSbInfo[1].gx });
        this.form.setFieldsValue({
          "familyList[0].sfzh": this.CszSbInfo[1].sfzh,
        });
      }
      this.jhrIsSb = 1; // 识别标识
      this.showCszModal = false;
    },
    // 出生证识别取消确认框
    cancelCszModal() {
      this.showCszModal = false;
      this.CszSbInfo = [];
    },
    // 清空识别信息
    clear(type) {
      if (type == "hjInfo") {
        this.hjProvinceDisabled = false;
        this.hjCityDisabled = false;
        this.hjCountyDisabled = false;
        this.hjAddressDisabled = false;
        // this.hkbFileList = []
        // this.hkbUrlList = []
        this.form.resetFields([
          "hjProvinceText",
          "hjProvinceCode",
          "hjCityText",
          "hjCityCode",
          "hjCountyText",
          "hjCountyCode",
        ]);
        this.hkbIsSb = 2;
      } else if (type == "fcInfo") {
        this.fczjBhDisabled = false;
        this.fczjSryDisabled = false;
        this.fcAddressDisabled = false;
        // this.fczjFileList = []
        // this.fczjUrlList = []
        this.fczjIsSb = 2;
        this.form.resetFields(["fcAddress", "fczjBh", "fczjSry"]);
      } else if (type == "cszInfo") {
        this.CszSbInfo = [];
        this.familyList = [
          {
            gx: "", // 与适龄儿童关系
            fXm: "", // 姓名
            sfzh: "", // 身份证号
            lxdh: "", // 联系电话
            dwType: "", // 单位类型
            sfdyjhy: "1", // // 第一监护人标识
          },
          {
            gx: "", // 与适龄儿童关系
            fXm: "", // 姓名
            sfzh: "", // 身份证号
            lxdh: "", // 联系电话
            dwType: "", // 单位类型
          },
        ];
        this.jhrIsSb = 2;
        this.form.resetFields([
          "familyList[0].fXm",
          "familyList[0].gx",
          "familyList[0].sfzh",
        ]);
      }
    },
    // 图片上传相关 start
    imgHandleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg或png格式图片!");
      }
      return isJpgOrPng;
    },
    selectImage(info, type, num) {
      // type  附件类型
      // num 1 识别   2 自己上传
      // console.log(666, info, type, num)
      //   if (info.file.size > 2097152) {
      if (info.file.size > 5242880) {
        this.$message.error(`请上传小于${this.fileSizeText}的图片!`);
        return;
      }
      if (info.file.status == "uploading") {
        if (type == "hkb") {
          this.hkbFileList = info.fileList;
        } else if (type == "hkbhz") {
          this.hkbHzFileList = info.fileList;
        } else if (type == "hkbjhr") {
          this.hkbJhrFileList = info.fileList;
        } else if (type == "hkbxs") {
          this.hkbXsFileList = info.fileList;
        } else if (type == "fczj") {
          this.fczjFileList = info.fileList;
        } else if (type == "jzz") {
          this.jzzFileList = info.fileList;
        } else if (type == "yyzz") {
          this.yyzzFileList = info.fileList;
        } else if (type == "jhz") {
          this.jhzFileList = info.fileList;
        } else if (type == "csz") {
          this.cszFileList = info.fileList;
        }
      }
      if (info.file.status == "done") {
        if (type == "hkb") {
          this.hkbUrlList.push(info.file.response.result.path);
        } else if (type == "hkbhz") {
          this.hkbHzUrlList.push(info.file.response.result.path);
        } else if (type == "hkbjhr") {
          this.hkbJhrUrlList.push(info.file.response.result.path);
        } else if (type == "hkbxs") {
          this.hkbXsUrlList.push(info.file.response.result.path);
        } else if (type == "fczj") {
          this.fczjUrlList.push(info.file.response.result.path);
        } else if (type == "jzz") {
          this.jzzUrlList.push(info.file.response.result.path);
        } else if (type == "yyzz") {
          this.yyzzUrlList.push(info.file.response.result.path);
        } else if (type == "jhz") {
          this.jhzUrlList.push(info.file.response.result.path);
        } else if (type == "csz") {
          this.cszUrlList.push(info.file.response.result.path);
        }
        this.$message.success("上传成功！");
        // 图片识别
        if (num == 1) {
          this.scanImg(type, info.file.response.result.path);
        }
      }
      if (info.file.status == "removed") {
        if (type == "hkb") {
          if (info.file.response != undefined) {
            this.hkbUrlList.splice(info.file.response.result.path, 1);
            this.hkbFileList.splice(info.file.response.result.path, 1);
          } else {
            this.hkbUrlList.splice(0, 1);
            this.hkbFileList.splice(0, 1);
          }
        } else if (type == "hkbhz") {
          if (info.file.response != undefined) {
            this.hkbHzUrlList.splice(info.file.response.result.path, 1);
            this.hkbHzFileList.splice(info.file.response.result.path, 1);
          } else {
            this.hkbHzUrlList.splice(0, 1);
            this.hkbHzFileList.splice(0, 1);
          }
        } else if (type == "hkbjhr") {
          if (info.file.response != undefined) {
            this.hkbJhrUrlList.splice(info.file.response.result.path, 1);
            this.hkbJhrFileList.splice(info.file.response.result.path, 1);
          } else {
            this.hkbJhrUrlList.splice(0, 1);
            this.hkbJhrFileList.splice(0, 1);
          }
        } else if (type == "hkbxs") {
          if (info.file.response != undefined) {
            this.hkbXsUrlList.splice(info.file.response.result.path, 1);
            this.hkbXsFileList.splice(info.file.response.result.path, 1);
          } else {
            this.hkbXsUrlList.splice(0, 1);
            this.hkbXsFileList.splice(0, 1);
          }
        } else if (type == "fczj") {
          if (info.file.response != undefined) {
            this.fczjUrlList.splice(info.file.response.result.path, 1);
            this.fczjFileList.splice(info.file.response.result.path, 1);
          } else {
            this.fczjUrlList.splice(0, 1);
            this.fczjFileList.splice(0, 1);
          }
        } else if (type == "jzz") {
          if (info.file.response != undefined) {
            this.jzzUrlList.splice(info.file.response.result.path, 1);
            this.jzzFileList.splice(info.file.response.result.path, 1);
          } else {
            this.jzzUrlList.splice(0, 1);
            this.jzzFileList.splice(0, 1);
          }
        } else if (type == "yyzz") {
          if (info.file.response != undefined) {
            this.yyzzUrlList.splice(info.file.response.result.path, 1);
            this.yyzzFileList.splice(info.file.response.result.path, 1);
          } else {
            this.yyzzUrlList.splice(0, 1);
            this.yyzzFileList.splice(0, 1);
          }
        } else if (type == "jhz") {
          if (info.file.response != undefined) {
            this.jhzUrlList.splice(info.file.response.result.path, 1);
            this.jhzFileList.splice(info.file.response.result.path, 1);
          } else {
            this.jhzUrlList.splice(0, 1);
            this.jhzFileList.splice(0, 1);
          }
        } else if (type == "csz") {
          if (info.file.response != undefined) {
            this.cszUrlList.splice(info.file.response.result.path, 1);
            this.cszFileList.splice(info.file.response.result.path, 1);
          } else {
            this.cszUrlList.splice(0, 1);
            this.cszFileList.splice(0, 1);
          }
        }
      }
    },
    // 图片上传相关 end

    // 性别
    sexChange(e) {
      console.log(e);
    },
    // 是否有房
    sfyfChange(e) {
      this.fcYw = e.target.value;
    },
    // 是否有居住证
    sfyjzzChange(e) {
      this.jzzYw = e.target.value;
      if (this.jzzYw == 1 && this.areaLS == 2) {
        this.getPcsList();
      }
    },
    // 是否有营业执照
    sfyyyzzChange(e) {
      this.yyzzYw = e.target.value;
    },
    // 提交
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.hkbIsSb = this.hkbIsSb; // 户口簿识别 1是   2否
        values.fczjIsSb = this.fczjIsSb; // 房产证识别 1是   2否
        values.jhrIsSb = this.jhrIsSb; // 出生证识别 1是   2否
        values.ssqy = this.areaLS;
        values.schoolCode = localStorage.getItem("schoolCode");
        values.djType = localStorage.getItem("stage");
        values.djTypeValue = localStorage.getItem("stageText");
        values.bmStageId = localStorage.getItem("bmStageId");
        values.csrq = moment(values.csrq).format("YYYY-MM-DD");
        values.id = this.detailInfo.id;

        values.hjProvinceText = this.hjProvinceText;
        values.hjCityText = this.hjCityText;
        values.hjCountyText = this.hjCountyText;
        values.hjProvinceCode = this.hjProvinceCode;
        values.hjCityCode = this.hjCityCode;
        values.hjCountyCode = this.hjCountyCode;

        if (this.hkbUrlList.length > 0) {
          values.hkbUrl = this.hkbUrlList.join(",");
        }
        if (this.hkbHzUrlList.length > 0) {
          values.hkbHzUrl = this.hkbHzUrlList.join(",");
        }
        if (this.hkbJhrUrlList.length > 0) {
          values.hkbJhrUrl = this.hkbJhrUrlList.join(",");
        }
        if (this.hkbXsUrlList.length > 0) {
          values.hkbXsUrl = this.hkbXsUrlList.join(",");
        }
        if (this.fczjUrlList.length > 0) {
          values.fczjUrl = this.fczjUrlList.join(",");
        }
        if (this.jzzUrlList.length > 0) {
          values.jzzUrl = this.jzzUrlList.join(",");
        }
        if (this.yyzzUrlList.length > 0) {
          values.yyzzUrl = this.yyzzUrlList.join(",");
        }
        if (this.jhzUrlList.length > 0) {
          values.jhzUrl = this.jhzUrlList.join(",");
        }
        if (this.cszUrlList.length > 0) {
          values.cszUrl = this.cszUrlList.join(",");
        }
        this.familyList[0].fXm = values.familyList[0].fXm;
        this.familyList[0].gx = values.familyList[0].gx;
        this.familyList[0].sfzh = values.familyList[0].sfzh;
        this.familyList[0].lxdh = values.familyList[0].lxdh;
        this.familyList[0].dwType = values.familyList[0].dwType;
        values.familyList = this.familyList;

        if (this.fcYw == 1) {
          if (values.fczjSry) {
            let sfjhy = false;
            for (let j = 0; j < this.familyList.length; j++) {
              if (this.familyList[j].fXm != "") {
                if (
                  this.familyList[0].fXm.trim() == values.fczjSry.trim() ||
                  this.familyList[1].fXm.trim() == values.fczjSry.trim()
                ) {
                  sfjhy = true;
                }
              }
            }
            if (!sfjhy && this.xm.trim() != values.fczjSry.trim()) {
              this.$message.error("房产所有权人与监护人或学生本人不一致");
              return;
            }
          }
        }
        if (this.jzzYw == 1) {
          if (values.jzzCry) {
            for (let j = 0; j < this.familyList.length; j++) {
              if (this.familyList[j].fXm != "") {
                if (
                  this.familyList[0].fXm.trim() == values.jzzCry.trim() ||
                  this.familyList[1].fXm.trim() == values.jzzCry.trim()
                ) {
                  break;
                } else {
                  this.$message.error("居住证持有人与监护人不一致");
                  return;
                }
              }
            }
          }
        }
        if (this.yyzzYw == 1) {
          if (values.yyzzFr) {
            for (let j = 0; j < this.familyList.length; j++) {
              if (this.familyList[j].fXm != "") {
                if (
                  this.familyList[0].fXm.trim() == values.yyzzFr.trim() ||
                  this.familyList[1].fXm.trim() == values.yyzzFr.trim()
                ) {
                  break;
                } else {
                  this.$message.error("营业执照法人与监护人不一致");
                  return;
                }
              }
            }
          }
        }
        if (!err) {
          this.btnDisabled = true;
          registerTwo(values).then((res) => {
            this.btnDisabled = false;
            if (res.code == 0) {
              // this.$message.success(res.description);
              this.registerTitle = res.msg;
              this.registerDesc = res.description;
              this.registerVisible = true;
            } else {
              this.$message.error(res.description);
            }
          });
        }
      });
    },
    // 发送验证码
    sendCode() {
      const phone = this.form.getFieldValue("familyList[0].lxdh");
      if (!phone) {
        this.$message.error("请填写手机号");
        return;
      } else {
        if (!telValid(phone.trim())) {
          this.$message.error("请填写正确格式的手机号码");
          return;
        }
      }
      this.codeDisabled = true;
      registerCode({
        phone: phone.trim(),
      }).then((res) => {
        if (res.code == 0) {
          this.$message.info(res.description);
          const _this = this;
          let coden = 60; // 定义60秒的倒计时
          let codeV = setInterval(function () {
            _this.btnText = "重新获取" + --coden + "s";
            if (coden == -1) {
              // 清除setInterval倒计时，按钮变回原样
              clearInterval(codeV);
              _this.codeDisabled = false;
              _this.btnText = "获取验证码";
            }
          }, 1000);
        } else {
          this.$message.error(res.description);
        }
      });
    },
    // 户籍信息
    provinceChange(e) {
      this.form.resetFields([
        "hjCityText",
        "hjCityCode",
        "hjCountyText",
        "hjCountyCode",
      ]);
      this.provinceList.forEach((pro) => {
        if (pro.rSname == e) {
          this.cityList = pro.item;
          this.hjProvinceText = pro.rSname;
          this.hjProvinceCode = pro.rCode;
          // 市排序处理
          let cityObj = {};
          for (var i = 0; i < this.cityList.length; i++) {
            if (this.cityList[i].rCode == 141100) {
              cityObj = this.cityList[i];
              this.cityList.splice(i, 1);
              break;
            }
          }
          this.cityList.unshift(cityObj);
        }
      });
    },
    cityChange(e) {
      this.form.resetFields(["hjCountyText", "hjCountyCode"]);
      this.cityList.forEach((city) => {
        if (city.rSname == e) {
          this.areaList = city.item;
          this.hjCityText = city.rSname;
          this.hjCityCode = city.rCode;
        }
      });
    },
    areaChange(e) {
      console.log(e);
      this.areaList.forEach((area) => {
        if (area.rSname == e) {
          this.hjCountyText = area.rSname;
          this.hjCountyCode = area.rCode;
        }
      });
      if (this.hjCountyCode == this.systemAreaCode) {
        this.areaLS = 1;
        this.getPcsList();
      } else {
        this.areaLS = 2;
      }
    },
    // 户籍信息 end
    // 房产证件类型
    fczjLxChange(e) {
      this.fczjLx = e;
    },
    pcsChange(e) {},
    gxChange(e) {},
    dwTypeChange(e) {},
    // 获取行政区划
    getCity() {
      getCity().then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              this.provinceList.push(element);
            });
            // 省份排序处理
            let proObj = {};
            for (var i = 0; i < this.provinceList.length; i++) {
              if (this.provinceList[i].rCode == 140000) {
                proObj = this.provinceList[i];
                this.provinceList.splice(i, 1);
                break;
              }
            }
            this.provinceList.unshift(proObj);
          }
          this.getDetailInfo(); // 回显信息
        } else {
          this.$message.error(res.description);
        }
      });
    },
    // 获取字典
    getDic() {
      const _this = this;
      getDicAll({
        schoolCode: localStorage.getItem("schoolCode"),
        types: "804001,804002,804003,804006,804009",
      }).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            res.data.filter(function (item) {
              // 身份证件类型
              if (item.code == 804001) {
                _this.zjlxList = item.dicts;
              }
              // 房产证件类型
              if (item.code == 804002) {
                _this.fczjlxList = item.dicts;
              }
              // 房产关系类型
              if (item.code == 804003) {
                _this.gxList = item.dicts;
              }
              // 房产关系类型
              if (item.code == 804009) {
                _this.fcgxList = item.dicts;
              }
              // 工作/就业单位类型
              if (item.code == 804006) {
                _this.dwTypeList = item.dicts;
              }
            });
          }
          this.getStreetList(); // 街道列表
          this.getCity(); // 行政区划
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 注册信息回显
    getDetailInfo() {
      getDetail().then((res) => {
        this.detailInfo = res.data;
        this.areaLS = res.data.ssqy;
        if (this.areaLS == 1) {
          this.getPcsList();
        }
        this.detailInfo.csrq = moment(res.data.csrq).format("YYYY-MM-DD");
        if (res.data.yyzzZcsj) {
          this.detailInfo.yyzzZcsj = moment(res.data.yyzzZcsj).format(
            "YYYY-MM-DD"
          );
        }
        if (res.data.hjDjrq) {
          this.detailInfo.hjDjrq = moment(res.data.hjDjrq).format("YYYY-MM-DD");
        }
        this.fcYw = res.data.fcYw;
        this.fczjLx = res.data.fczjLx;
        this.jzzYw = res.data.jzzYw;
        this.yyzzYw = res.data.yyzzYw;
        this.hkbFileList = [];
        this.hkbHzFileList = [];
        this.hkbJhrFileList = [];
        this.hkbXsFileList = [];
        this.jhzFileList = [];
        this.cszFileList = [];
        this.fczjFileList = [];
        this.jzzFileList = [];
        this.yyzzFileList = [];
        if (res.data.hkbUrl) {
          this.hkbUrlList = res.data.hkbUrl.split(",");
          for (let i = 0; i < this.hkbUrlList.length; i++) {
            const obj = {
              url: this.hkbUrlList[i],
              uid: i,
              status: "done",
              name: `a${i}.jpg`,
            };
            this.hkbFileList.push(obj);
          }
        }
        if (res.data.hkbHzUrl) {
          this.hkbHzUrlList = res.data.hkbHzUrl.split(",");
          for (let i = 0; i < this.hkbHzUrlList.length; i++) {
            const obj = {
              url: this.hkbHzUrlList[i],
              uid: i,
              status: "done",
              name: `a${i}.jpg`,
            };
            this.hkbHzFileList.push(obj);
          }
        }
        if (res.data.hkbJhrUrl) {
          this.hkbJhrUrlList = res.data.hkbJhrUrl.split(",");
          for (let i = 0; i < this.hkbJhrUrlList.length; i++) {
            const obj = {
              url: this.hkbJhrUrlList[i],
              uid: i,
              status: "done",
              name: `a${i}.jpg`,
            };
            this.hkbJhrFileList.push(obj);
          }
        }
        if (res.data.hkbXsUrl) {
          this.hkbXsUrlList = res.data.hkbXsUrl.split(",");
          for (let i = 0; i < this.hkbXsUrlList.length; i++) {
            const obj = {
              url: this.hkbXsUrlList[i],
              uid: i,
              status: "done",
              name: `a${i}.jpg`,
            };
            this.hkbXsFileList.push(obj);
          }
        }
        if (res.data.jhzUrl) {
          this.jhzUrlList = res.data.jhzUrl.split(",");
          for (let i = 0; i < this.jhzUrlList.length; i++) {
            const obj = {
              url: this.jhzUrlList[i],
              uid: i,
              status: "done",
              name: `a${i}.jpg`,
            };
            this.jhzFileList.push(obj);
          }
        }
        if (res.data.cszUrl) {
          this.cszUrlList = res.data.cszUrl.split(",");
          for (let i = 0; i < this.cszUrlList.length; i++) {
            const obj = {
              url: this.cszUrlList[i],
              uid: i,
              status: "done",
              name: `a${i}.jpg`,
            };
            this.cszFileList.push(obj);
          }
        }
        if (res.data.fczjUrl) {
          this.fczjUrlList = res.data.fczjUrl.split(",");
          for (let i = 0; i < this.fczjUrlList.length; i++) {
            const obj = {
              url: this.fczjUrlList[i],
              uid: i,
              status: "done",
              name: `a${i}.jpg`,
            };
            this.fczjFileList.push(obj);
          }
        }
        if (res.data.jzzUrl) {
          this.jzzUrlList = res.data.jzzUrl.split(",");
          for (let i = 0; i < this.jzzUrlList.length; i++) {
            const obj = {
              url: this.jzzUrlList[i],
              uid: i,
              status: "done",
              name: `a${i}.jpg`,
            };
            this.jzzFileList.push(obj);
          }
        }
        if (res.data.yyzzUrl) {
          this.yyzzUrlList = res.data.yyzzUrl.split(",");
          for (let i = 0; i < this.yyzzUrlList.length; i++) {
            const obj = {
              url: this.yyzzUrlList[i],
              uid: i,
              status: "done",
              name: `a${i}.jpg`,
            };
            this.yyzzFileList.push(obj);
          }
        }
        this.hjProvinceText = this.detailInfo.hjProvinceText;
        this.hjCityText = this.detailInfo.hjCityText;
        this.hjCountyText = this.detailInfo.hjCountyText;
        this.hjProvinceCode = this.detailInfo.hjProvinceCode;
        this.hjCityCode = this.detailInfo.hjCityCode;
        this.hjCountyCode = this.detailInfo.hjCountyCode;

        if (this.provinceList.length > 0) {
          this.provinceList.forEach((pro) => {
            if (pro.rSname == this.hjProvinceText) {
              this.cityList = pro.item;
            }
          });
        }
        if (this.cityList.length > 0) {
          this.cityList.forEach((city) => {
            if (city.rSname == this.hjCityText) {
              this.areaList = city.item;
            }
          });
        }
        this.setFormData();
      });
    },
    setFormData() {
      const formData = pick(this.detailInfo, [
        "id",
        "djType",
        "djTypeValue",
        "xb",
        "xm",
        "zjh",
        "csrq",
        "hjProvinceText",
        "hjProvinceCode",
        "hjCityCode",
        "hjCityText",
        "hjCountyText",
        "hjCountyCode",
        "hjAddress",
        "hjSzdpcs",
        "hjDjrq",
        "hkbUrl",
        "hkbHzUrl",
        "hkbJhrUrl",
        "hkbXsUrl",
        "jhzUrl",
        "cszUrl",
        "ssqy",
        "familyList",
        "fcYw",
        "fcStreet",
        "fcCommunity",
        "fcAddress",
        "fczjLx",
        "fczjBh",
        "fczjSry",
        "fczjYxxgx",
        "fczjUrl",
        "jzzYw",
        "jzzCry",
        "jzzBh",
        "jzzFzjg",
        "jzzStreet",
        "jzzCommunity",
        "jzzAddress",
        "jzzUrl",
        "yyzzYw",
        "yyzzFr",
        "yyzzZcsj",
        "yyzzBh",
        "yyzzDjAddress",
        "yyzzSfnj",
        "yyzzUrl",
      ]);
      this.$nextTick(() => {
        this.form.setFieldsValue(formData);
      });
    },
  },
  mounted() {
    this.getDic();
  },
};
</script>

<style lang="less" scoped>
.page {
  // background: url(~@/assets/user/bg.png) center bottom no-repeat;
  background: #f5f5f5;
  min-height: 100%;
  width: 100%;
  background-size: cover;
}
.tip {
  color: #ff7575;
  font-size: 14px;
}
.wrap {
  width: 1000px;
  margin: 0 auto 40px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.register {
  width: 822px;
  margin: 0 auto;
  padding: 40px 0;
  .step {
    width: 382px;
    margin: 0 auto 4px;
  }
  .alert {
    margin-top: 28px;
  }
}
.section {
  .part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f7f7;
    padding: 0px 16px;
    height: 40px;
    margin-bottom: 12px;
    margin-top: 28px;
  }
  .title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .line {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #35ab61;
    border-radius: 2px;
    margin-right: 6px;
  }
  .scan {
    font-size: 14px;
    color: #35ab61;
    cursor: pointer;
    /deep/ .ant-upload-list {
      display: none;
    }
  }
  .del {
    font-size: 14px;
    color: #ff5454;
    cursor: pointer;
  }
  .icon {
    margin-right: 6px;
  }
  .lookDemo {
    font-size: 14px;
    color: #35ab61;
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 8px;
  }
}
.ant-radio-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 40px;
  line-height: 27px;
  padding: 6px 11px;
  width: 181px;
  &:last-child {
    margin-right: 0;
  }
}
/deep/ .ant-form-item-control {
  line-height: inherit;
}
/deep/ .ant-form-item {
  margin-bottom: 12px;
}
/deep/ .ant-input {
  font-size: 14px;
}
/deep/ .ant-form-item-label > label::after {
  content: "";
}
/deep/ .ant-input-group-addon {
  background: transparent;
  border-left: 0;
}
/deep/ .ant-steps-item-title {
  font-size: 14px;
}
/deep/
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #35ab61;
}
/deep/ .ant-upload.ant-upload-select-picture-card {
  // width: 88px;
  // height: 88px;
}
.codeBtn {
  padding: 0 4px;
  // padding: 0 4px 0 14px;
  // border-left: 1px solid #ddd;
  // height: auto;
}

.ant-btn-primary {
  height: 44px;
  box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  margin-top: 28px;
}
.next {
  width: 372px;
}

.pre-button {
  border-color: #35ab61;
  background: #fff;
  color: #35ab61;
  box-shadow: none;
}
.resultWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    font-size: 48px;
    color: #35ab61;
    margin-bottom: 20px;
  }
  .title {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .desc {
    font-size: 14px;
    color: #666;
  }
  .result-btn {
    width: 280px;
    margin-top: 36px;
    margin-bottom: 16px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
}
</style>
